import { Form, Button, Row, Col } from 'react-bootstrap';
import ReactSwitch from './ReactSwitch';
import Utilities from '../Classes/Utilities';

function ArtIdentification(props){
  return (
    <div>
      <Row>
        <Col className="col-7">
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Numéro d'inventaire</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-num" onChange={e => props.updateArtData("inventory_number", e.target.value)} value={props.artData.inventory_number} type="text" placeholder="n° inventaire" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Titre</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-title" onChange={e => props.updateArtData("title", e.target.value)} value={props.artData.title} type="text" placeholder="titre de l'oeuvre" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Ancien numéro d'inventaire</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-oldNum" onChange={e => props.updateArtData("old_inventory_number", e.target.value)} value={props.artData.old_inventory_number} type="text" placeholder="ancien n° inventaire" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Atelier</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-workshop" onChange={e => props.updateArtData("workshop", e.target.value)} value={props.artData.workshop} defaultValue="null" aria-label="workshop">
                {props.workshopData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Aucun</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Nombre d'exemplaires</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-nbEx" onChange={e => props.updateArtData("number", e.target.value)} value={props.artData.number} type="number" placeholder="nombre" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Hauteur (cm)</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-height" onChange={e => props.updateArtData("height", e.target.value)} value={props.artData.height} type="number" placeholder="distance" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Largeur (cm)</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-width" onChange={e => props.updateArtData("width", e.target.value)} value={props.artData.width} type="number" placeholder="distance" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Profondeur (cm)</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-depth" onChange={e => props.updateArtData("depth", e.target.value)} value={props.artData.depth} type="number" placeholder="distance" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Diamètre (cm)</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-dia" onChange={e => props.updateArtData("diameter", e.target.value)} value={props.artData.diameter} type="number" placeholder="distance" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Poids (kg)</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-weight" onChange={e => props.updateArtData("weight", e.target.value)} value={props.artData.weight} defaultValue="null" aria-label="weight">
                {props.weightData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Inconnu</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Encadrement - Soclage</Form.Label>
                <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-frame" onChange={e => props.updateArtData("base_framing", e.target.value)} value={props.artData.base_framing} type="text" placeholder="cadre" />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Date de création</Form.Label>
                <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-creationDate" onChange={e => props.updateArtData("art_creation_date", e.target.value)} value={props.artData.art_creation_date} type="number" placeholder="aaaa" />
              </Form.Group>
            </Col>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Description</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-description" onChange={e => props.updateArtData("description", e.target.value)} value={props.artData.description} as="textarea" rows={4} placeholder="description de l'oeuvre" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Signature</Form.Label>
              <br />
              <ReactSwitch readOnly={props.sessionData.adminRights === 0} onChange={e => props.updateArtData("signed", e ? "1" : "0")} defaultValue={props.artData.signed == 1} id="modifyArt-signatureBool" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Emplacement signature</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-signaturePlace" onChange={e => props.updateArtData("signature_location", e.target.value)} value={props.artData.signature_location} type="text" placeholder="emplacement" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Technique principale d'exécution</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-technique" onChange={e => props.updateArtData("technique", e.target.value)} value={props.artData.technique} defaultValue="null" aria-label="workshop">
                {props.techniqueData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Aucun</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Support</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-support"  onChange={e => props.updateArtData("support", e.target.value)} value={props.artData.support} defaultValue="null" aria-label="workshop">
                {props.supportData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Aucun</option>
              </Form.Select>
            </Form.Group>
          </Row>
        </Col>
        <Col className="col-5">
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Nom de l'auteur</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-authorLastName" onChange={e => props.updateArtData("author_last_name", e.target.value)} value={props.artData.author_last_name} type="text" placeholder="nom" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Prénom de l'auteur</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-authorFirstName" onChange={e => props.updateArtData("author_first_name", e.target.value)} value={props.artData.author_first_name} type="text" placeholder="prénom" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Pseudonyme de l'auteur</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-pseudo" onChange={e => props.updateArtData("author_pseudo", e.target.value)} value={props.artData.author_pseudo} type="text" placeholder="pseudonyme" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Nationalité de l'auteur</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-authorNat" onChange={e => props.updateArtData("nationality", e.target.value)} value={props.artData.nationality} defaultValue="null" aria-label="nationality">
                {props.nationalityData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Aucun</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Année d'étude</Form.Label>
              <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-studyYear" onChange={e => props.updateArtData("study_year", e.target.value)} value={props.artData.study_year} defaultValue="null" aria-label="studyYear">
                {props.studyYearData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                <option value="null">Aucun</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Date de naissance</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-birthDate" onChange={e => props.updateArtData("birth_date", e.target.value)} value={props.artData.birth_date} type="date" />
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label>Date de décès</Form.Label>
              <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-deathDate" onChange={e => props.updateArtData("death_date", e.target.value)} value={props.artData.death_date} type="date" />
            </Form.Group>
          </Row>
          <div className="mb-3 image-placehodler">
            {props.image === "" ?
            <img src={Utilities.GetDistantImage(props.artData.main_picture_path)} className="img-contain" /> : 
            <img src={props.image} className="img-contain" />
            }
          </div>
          <Form.Group className='mb-3'>
            <Form.Label>Image</Form.Label>
            <Form.Control disabled={props.sessionData.adminRights === 0} id="modifyArt-image" onChange={props.pictureChangeHandler} type="file" accept="image/png, image/gif, image/jpeg" />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

export default ArtIdentification;