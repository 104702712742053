import { Table, Button, Col, Row, Form } from "react-bootstrap";
import Utilities from "../Classes/Utilities";

export default function Tags(props){
  return (
    <div>
      <div id="print-area">
        <div className="print-section">
          {props.tags.map(art => (
            <Row key={art.id} className="print-tag mb-3">
              <Col className="col-2">{art.inventory_number}</Col>
              <Col className="col-2">
                <div className="image-placeholder">
                  <img src={Utilities.GetDistantImage(art.main_picture_path)} className="img-contain"></img>
                </div>
              </Col>
              <Col className="col-8">
                <Row>
                  <Col><strong>Auteur</strong></Col>
                  <Col>{art.author_last_name}</Col>
                  <Col>{art.author_first_name}</Col>
                </Row>
                <Row>
                  <Col><strong>Description</strong></Col>
                  <Col>{art.description}</Col>
                </Row>
                <Row>
                  <Col><strong>Support</strong></Col>
                  <Col>{art.support}</Col>
                  <Col><strong>Emplacement</strong></Col>
                  <Col>{art.usual_location}</Col>
                </Row>
                <Row>
                  <Col><strong>Dimensions</strong></Col>
                  <Col>H {art.height}</Col>
                  <Col>L {art.width}</Col>
                  <Col>P {art.weight}</Col>
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </div>
      <div className='p-3 m-auto text-center'>
        <Row className='justify-content-center'>
          <Col className='col-1'>
            <Form.Label className='pt-1'>Étiquettes</Form.Label>
          </Col>
          <Col className='col-1'>
            <Button onClick={window.print}>Imprimer</Button>
          </Col>
          <Col className='col-1'>
            <Button variant="danger" onClick={props.onTagClear}>Vider</Button>
          </Col>
        </Row>
      </div>
      <div className='p-3 m-auto d-flex justify-content-center'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>N° Inventaire</th>
              <th>Titre</th>
              <th>Nom artiste</th>
              <th>Prénom artiste</th>
              <th>Support</th>
              <th>Emplacement</th>
              <th>Hauteur</th>
              <th>Largeur</th>
              <th>Poids</th>
            </tr>
          </thead>
          <tbody>
            {props.tags.map(art => (
              <tr key={art.id}>
                <td onClick={() => props.onArt(art.id)}>{art.inventory_number}</td>
                <td onClick={() => props.onArt(art.id)}>{art.title}</td>
                <td onClick={() => props.onArt(art.id)}>{art.author_last_name}</td>
                <td onClick={() => props.onArt(art.id)}>{art.author_first_name}</td>
                <td onClick={() => props.onArt(art.id)}>{art.support}</td>
                <td onClick={() => props.onArt(art.id)}>{art.usual_location}</td>
                <td onClick={() => props.onArt(art.id)}>{art.height}</td>
                <td onClick={() => props.onArt(art.id)}>{art.width}</td>
                <td onClick={() => props.onArt(art.id)}>{art.weight}</td>
                <td><Button onClick={() => props.onTagRemove(art.id)} className="tag-btn" variant="danger">- 🏷️</Button></td>
              </tr>
            ))}
          </tbody> 
        </Table>
      </div>
    </div>
  );
}