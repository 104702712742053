import { Row, Form } from "react-bootstrap";
import { useState } from "react";
import FormType from "./FormType";

function Forms(props){

  let [formType, setFormType] = useState("StudyYear");

  return (
    <div>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Type de formulaire</Form.Label>
          <Form.Select defaultValue={formType} onChange={e => setFormType(e.target.value)} id="admin-form" aria-label="type">
            <option value="StudyYear">Année d'étude</option>
            <option value="Workshop">Atelier</option>
            <option value="Packaging">Emballage</option>
            <option value="GeneralStatus">État général</option>
            <option value="Acquisition">Mode d'acquisition</option>
            <option value="Nationality">Nationalité de l'auteur</option>
            <option value="Weight">Poids</option>
            <option value="Preservation">Proposition de conservation</option>
            <option value="Restoration">Proposition de restauration</option>
            <option value="Support">Support</option>
            <option value="SupportStatus">Support (État de conservation)</option>
            <option value="SurfaceStatus">Surface (État de conservation)</option>
            <option value="Technique">Technique principale d'exécution</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <FormType type={formType} sessionData={props.sessionData} />
      </Row>
    </div>
  );
}

export default Forms;