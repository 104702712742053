import { useEffect, useState } from 'react';
import { Navbar, Row, Col, Container, Nav, NavDropdown } from 'react-bootstrap';
import Session from "./Classes/Session";
import Router from "./Components/Router";
import Login from "./Components/Login";
import Home from "./Components/Home";
import Tags from "./Components/Tags";
import AddArt from "./Components/AddArt";
import ModifyArt from "./Components/ModifyArt";
import logo from "./img/lacambre-logo-full.svg";
import Admin from './Components/Admin';

function App(){

  const [currentRoute, setCurrentRoute] = useState("login");
  const [sessionData, setSessionData] = useState(Session.getSession());
  const [ops, setOps] = useState({
    login: false
  });
  const [loginError, setLoginError] = useState("");
  const [artId, setArtId] = useState(null);
  const [tags, setTags] = useState([]);
  
  async function ConnectHandler(login, pwd){
    let newOps = structuredClone(ops);
    newOps.login = true;
    setOps(newOps);
    Session.Connect(login, pwd, () => {
      newOps.login = false;
      setSessionData(Session.getSession());
      setOps(newOps);
      setCurrentRoute("home");
      setLoginError("");
    }, errorMessage => {
      newOps.login = false;
      setSessionData(Session.getSession());
      setOps(newOps);
      setLoginError(errorMessage);
    });
  }

  function DisconnectHandler(){
    Session.Disconnect();
    setSessionData(Session.getSession());
    setCurrentRoute("login");
  }

  function ArtSeclectionHandler(iArtId){
    setArtId(iArtId);
  }

  function AddTag(element){
    if(!tags.some(e => e.id === element.id)){
      let tmpTags = structuredClone(tags);
      tmpTags.push(element);
      setTags(tmpTags);
    }
  }

  function RemoveTag(idToRemove){
    let tmpArray = [];
    for(let tg of tags){
      if(tg.id !== idToRemove){
        tmpArray.push(tg);
      }
    }
    setTags(tmpArray);
  }

  function ClearTag(){
    setTags([]);
  }

  useEffect(() => {
    if(artId !== null) setCurrentRoute("modifyArt")
  }, [artId]);

  useEffect(() => {
    if(currentRoute !== "modifyArt") setArtId(null);
    if(currentRoute === "login") setTags([]);
  }, [currentRoute]);

  return(
    <div id='main'>
      <Navbar className='p-3' id="navbar" bg="light" expand="sm">
        <Navbar.Brand>
          <Container>
            <Row>
              <Col className="text-center"><img src={logo}></img></Col>
            </Row>
            <Row>
              <Col className="text-center">Inventaire</Col>
            </Row>
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle />
        {sessionData.loggedin ? 
          <Nav className="me-auto">
            <Nav.Link onClick={() => setCurrentRoute("home")}>Accueil</Nav.Link>
            {sessionData.adminRights >= 1 ? 
              <Nav.Link onClick={() => setCurrentRoute("addArt")}>Nouvel enregistrement</Nav.Link> 
            : null}
            {sessionData.adminRights >= 1 ?
               <Nav.Link onClick={() => setCurrentRoute("admin")}>Administration</Nav.Link>
            : null}
            {tags.length > 0 ? <Nav.Link onClick={() => setCurrentRoute("tags")}>Étiquettes - {tags.length}</Nav.Link> : null}
          </Nav>
        : null}
        {sessionData.loggedin ? 
          <Nav className="justify-content-end">
            <NavDropdown title={`Connecté en tant que: ${sessionData.username}`} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={DisconnectHandler}>Déconnexion</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        : ''}
      </Navbar>
      <div id="view">
        <Router currentRoute={currentRoute}>
          <Login route-id="login" opStatus={ops.login} onConnect={ConnectHandler} loginError={loginError} />
          <Home route-id="home" tags={tags} onTagAdd={AddTag} onTagRemove={RemoveTag} onArt={ArtSeclectionHandler} sessionData={sessionData} />
          <AddArt route-id="addArt" sessionData={sessionData} setArtId={setArtId} setCurrentRoute={setCurrentRoute} />
          <ModifyArt route-id="modifyArt" artId={artId} sessionData={sessionData} setCurrentRoute={setCurrentRoute} />
          <Tags route-id="tags" tags={tags} onTagRemove={RemoveTag} onTagClear={ClearTag} />
          <Admin route-id="admin" sessionData={sessionData} />
        </Router>
      </div>
    </div>
  )
}

export default App;
