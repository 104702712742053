import FormImageSet from "./FormImageSet";
import { Form } from "react-bootstrap"; 

function ConstatAnnex(props){
  return (
    <div>
      <FormImageSet readOnly={props.sessionData.adminRights === 0} refresh={props.refresh} type="side" onDeletePicture={props.onDeletePicture} onAddPicture={props.onAddPicture} updateLegend={props.sideUpdateLegend} addPictures={props.addPictures} imageSet={props.artData.side_picture}></FormImageSet>
      <Form.Group className='mb-3'>
        <Form.Label>Informations supplémentaires</Form.Label>
        <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-locationReason"onChange={e => props.updateArtData("side_info", e.target.value)} value={props.artData.side_info} as="textarea" rows={4} placeholder="Informations supplémentaires" />
      </Form.Group>
    </div>
  );
}

export default ConstatAnnex;