import { useState, useEffect } from "react";
import $ from "jquery";
import { Form, Button, Row, Col } from 'react-bootstrap';
import ReactSwitch from "./ReactSwitch";
import loading from "../img/loading.svg";
import RequestHandler from "../Classes/RequestHandler";
import Art from '../Classes/Art';

function AddArt(props){
  const [workshopData, setWorkshopData] = useState([]);
  const [techniqueData, setTechniqueData] = useState([]);
  const [supportData, setSupportData] = useState([]);
  const [nationalityData, setNationalityData] = useState([]);
  const [studyYearData, setStudyYearData] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [image, setImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [opStatus, setOpStatus] = useState(false);
  const [signatureSwitch, setSignatureSwitch] = useState(false);
  useEffect(() => {
    async function effect(){
      let display = true;

      await RequestHandler.Get("/Workshop", {token: props.sessionData.jwt}, 
      data => setWorkshopData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Technique", {token: props.sessionData.jwt}, 
      data => setTechniqueData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Support", {token: props.sessionData.jwt}, 
      data => setSupportData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Nationality", {token: props.sessionData.jwt}, 
      data => setNationalityData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/StudyYear", {token: props.sessionData.jwt}, 
      data => setStudyYearData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Weight", {token: props.sessionData.jwt}, 
      data => setWeightData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      if(display) {
        setLoaded(true);
      }
    }
    effect();
  }, []);

  function pictureChangeHandler(e){
    const reader = new FileReader();
    reader.onload = f => {
      setImage(f.target.result);
    }
    reader.readAsDataURL(e.target.files[0]);
  }
  function registerHandler(){
    setOpStatus(true);
    let num = $("#addArt-num").val();
    let title = $("#addArt-title").val();
    let oldNum = $("#addArt-oldNum").val();
    let workshop = $("#addArt-workshop").val();
    let nbEx = $("#addArt-nbEx").val();
    let height = $("#addArt-height").val();
    let width = $("#addArt-width").val();
    let depth = $("#addArt-depth").val();
    let dia = $("#addArt-dia").val();
    let weight = $("#addArt-weight").val();
    let frame = $("#addArt-frame").val();
    let creationDate = $("#addArt-creationDate").val();
    let description = $("#addArt-description").val();
    let signature = signatureSwitch;
    let signaturePlace = $("#addArt-signaturePlace").val();
    let technique = $("#addArt-technique").val();
    let support = $("#addArt-support").val();
    let authorLastName = $("#addArt-authorLastName").val();
    let authorFirstName = $("#addArt-authorFirstName").val();
    let pseudo = $("#addArt-pseudo").val();
    let nat = $("#addArt-authorNat").val();
    let studyYear = $("#addArt-studyYear").val();
    let birthDate = $("#addArt-birthDate").val();
    let deathDate = $("#addArt-deathDate").val();
    //TODO: Validate data
    if(num === ""){
      setOpStatus(false);
      return setError("Le numéro d'inventaire ne peux pas être vide.");
    }
    if(isNaN(nbEx)){
      setOpStatus(false);
      return setError("La nombre d'exemplaire doit être un nombre.");
    }
    if(isNaN(height)){
      setOpStatus(false);
      return setError("La hauteur doit être un nombre.");
    }
    if(isNaN(width)){
      setOpStatus(false);
      return setError("La largeur doit être un nombre.");
    }
    if(isNaN(depth)){
      setOpStatus(false);
      return setError("La profondeur doit être un nombre.");
    }
    if(isNaN(dia)){
      setOpStatus(false);
      return setError("Le diamètre doit être un nombre.");
    }
    if(isNaN(creationDate)){
      setOpStatus(false);
      return setError("L'année de création doit être un nombre.")
    }

    //Send data
    let artCretDate = new Date(creationDate);
    let strDate = `${artCretDate.getFullYear()}-${artCretDate.getMonth()+1}-${artCretDate.getDate()}`;

    let fd = new FormData();
    fd.append("inventory_number", num);
    if(oldNum !== "") fd.append("old_inventory_number", oldNum);
    if(title !== "") fd.append("title", title);
    if(workshop !== "null") fd.append("workshop", workshop);
    if(nbEx !== "") fd.append("number", nbEx);
    if(height !== "") fd.append("height", height);
    if(width !== "") fd.append("width", width);
    if(depth !== "") fd.append("depth", depth);
    if(dia !== "") fd.append("diameter", dia);
    if(weight !== "null") fd.append("weight", weight);
    if(frame !== "") fd.append("base_framing", frame);
    if(description !== "") fd.append("description", description);
    if(creationDate !== "") fd.append("art_creation_date", strDate);
    fd.append("signed", signature ? "1" : "0");
    if(signaturePlace !== "") fd.append("signature_location", signaturePlace);
    if(technique !== "null") fd.append("technique", technique);
    if(support !== "null") fd.append("support", support);
    if(authorLastName !== "") fd.append("author_last_name", authorLastName);
    if(authorFirstName !== "") fd.append("author_first_name", authorFirstName);
    if(pseudo !== "") fd.append("author_pseudo", pseudo);
    if(nat !== "null") fd.append("nationality", nat);
    if(studyYear !== "null") fd.append("study_year", studyYear);
    if(birthDate !== "") fd.append("birth_date", birthDate);
    if(deathDate !== "") fd.append("death_date", deathDate);
    if(window.document.getElementById("addArt-image").files[0]) fd.append("main_picture", window.document.getElementById("addArt-image").files[0]);
    
    Art.Add(props.sessionData.jwt, fd, data => {
      setOpStatus(false);
      props.setArtId(data.insertedId);
      props.setCurrentRoute("modifyArt");
    }, () => {
      setError("Une erreur est survenue lors de l'opération.");
      setOpStatus(false);
    })
  }
  return (
    <div className={loaded ? 'h-adjust pt-3' : 'd-flex flex-column h-adjust justify-content-center align-items-center'}>
        {loaded ? 
        <Form className='w-75 bg-light-blue p-4 m-auto rounded' noValidate>
          <h5 className="mb-3">Nouvel enregistrement</h5>
          <Row>
            <Col className="col-7">
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Numéro d'inventaire</Form.Label>
                  <Form.Control id="addArt-num" type="text" placeholder="n° inventaire" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Titre</Form.Label>
                  <Form.Control id="addArt-title" type="text" placeholder="titre de l'oeuvre" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Ancien numéro d'inventaire</Form.Label>
                  <Form.Control id="addArt-oldNum" type="text" placeholder="ancien n° inventaire" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Atelier</Form.Label>
                  <Form.Select id="addArt-workshop" defaultValue="null" aria-label="workshop">
                    {workshopData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Nombre d'exemplaires</Form.Label>
                  <Form.Control id="addArt-nbEx" type="number" placeholder="nombre" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Hauteur (cm)</Form.Label>
                  <Form.Control id="addArt-height" type="number" placeholder="distance" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Largeur (cm)</Form.Label>
                  <Form.Control id="addArt-width" type="number" placeholder="distance" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Profondeur (cm)</Form.Label>
                  <Form.Control id="addArt-depth" type="number" placeholder="distance" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Diamètre (cm)</Form.Label>
                  <Form.Control id="addArt-dia" type="number" placeholder="distance" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Poids</Form.Label>
                  <Form.Select id="addArt-weight" defaultValue="null" aria-label="weight">
                    {weightData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Encadrement - Soclage</Form.Label>
                    <Form.Control id="addArt-frame" type="text" placeholder="cadre" />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Date de création</Form.Label>
                    <Form.Control id="addArt-creationDate" type="number" placeholder="aaaa" />
                  </Form.Group>
                </Col>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control id="addArt-description" as="textarea" rows={4} placeholder="description de l'oeuvre" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Signature</Form.Label>
                  <br />
                  <ReactSwitch onChange={setSignatureSwitch} id="addArt-signatureBool" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Emplacement signature</Form.Label>
                  <Form.Control id="addArt-signaturePlace" type="text" placeholder="emplacement" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Technique principale d'exécution</Form.Label>
                  <Form.Select id="addArt-technique" defaultValue="null" aria-label="workshop">
                    {techniqueData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Support</Form.Label>
                  <Form.Select id="addArt-support" defaultValue="null" aria-label="workshop">
                    {supportData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            </Col>
            <Col className="col-5">
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Nom de l'auteur</Form.Label>
                  <Form.Control id="addArt-authorLastName" type="text" placeholder="nom" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Prénom de l'auteur</Form.Label>
                  <Form.Control id="addArt-authorFirstName" type="text" placeholder="prénom" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Pseudonyme de l'auteur</Form.Label>
                  <Form.Control id="addArt-pseudo" type="text" placeholder="pseudonyme" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Nationalité de l'auteur</Form.Label>
                  <Form.Select id="addArt-authorNat" defaultValue="null" aria-label="nationality">
                    {nationalityData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Année de fin d'étude</Form.Label>
                  <Form.Select id="addArt-studyYear" defaultValue="null" aria-label="studyYear">
                    {studyYearData.map((el, i) => <option key={i} value={el}>{el}</option>)}
                    <option value="null">Aucun</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control id="addArt-birthDate" type="date" />
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                  <Form.Label>Date de décès</Form.Label>
                  <Form.Control id="addArt-deathDate" type="date" />
                </Form.Group>
              </Row>
              <div className="mb-3 image-placehodler">
                {image === "" ?
                <p>Aucun fichier sélectionné</p> : 
                <img src={image} className="img-contain" />
                }
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Image</Form.Label>
                <Form.Control id="addArt-image" onChange={pictureChangeHandler} type="file" accept="image/png, image/gif, image/jpeg" />
              </Form.Group>
            </Col>
          </Row>
          {opStatus ? <img className="form-loading" src={loading}></img> : <Button onClick={registerHandler} variant="primary">Enregistrer</Button>}
        </Form> : 
        error === "" ? <img className="page-loading" src={loading}></img> : <p>{error}</p>}
    </div>
  );
}

export default AddArt;