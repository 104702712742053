import RequestHandler from "./RequestHandler";

const User = {};

User.GetAll = async (jwt, success, error) => {
  await RequestHandler.Get("/User", {token: jwt}, success, error);
}

User.Add = async (jwt, args, success, error) => {
  await RequestHandler.Post("/Register", args, {token: jwt}, success, error);
}

User.UpdatePassword = async (jwt, args, success, error) => {
  await RequestHandler.Post("/UserUpdatePassword", args, {token: jwt}, success, error);
}

User.UpdateData = async (jwt, args, success, error) => {
  await RequestHandler.Post("/UserUpdateData", args, {token: jwt}, success, error);
}

User.Delete = async (jwt, id, success, error) => {
  await RequestHandler.Delete(`/User/${id}`, {token: jwt,}, success, error);
}

export default User;