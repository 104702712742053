import { Col, Row, Table, Form, Button } from "react-bootstrap";
import RequestHandler from "../Classes/RequestHandler";
import { useState, useEffect } from "react";
import loadingsvg from "../img/loading.svg";

function FormType(props){

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [formData, setFormData] = useState([]);
  let [selected, setSelected] = useState(null);
  let [refresh, setRefresh] = useState(false);

  let [newData, setNewData] = useState("");

  useEffect(() => {
    setLoading(true);
    setSelected(null);

    RequestHandler.Get(`/${props.type}`, {token: props.sessionData.jwt}, data => {
      setFormData(data);
      setError(false);
      setLoading(false);
    }, () => {
      setError("Erreur de chargement des données, veuillez réessayer plus tard.");
      setLoading(false);
    });
  }, [refresh, props.type]);

  function addHandler(){
    let fd = new FormData();
    fd.append("name", newData);
    RequestHandler.Post(`/${props.type}/add`, fd, {token: props.sessionData.jwt}, () => {
      setRefresh(!refresh);
    }, () => {
      setError("Erreur lors de l'opération");
    });
  }

  function updateHandler(){
    RequestHandler.Put(`/${props.type}/update`, {id: selected.id, name: selected.name}, {token: props.sessionData.jwt}, () => {
      setRefresh(!refresh);
    }, () => {
      setError("Erreur lors de l'opération");
    });
  }

  function deleteHandler(){
    RequestHandler.Delete(`/${props.type}/delete`, {id: selected.id, token: props.sessionData.jwt}, () => {
      setRefresh(!refresh);
    }, () => {
      setError("Erreur lors de l'opération");
    });
  }

  function selectedUpdateHandler(value){
    let tmp = structuredClone(selected);
    tmp.name = value;
    setSelected(tmp);
  }

  return(
    <div>
      <Row>
        <Col>
          <div id="user-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nom de l'élément</th>
                </tr>
              </thead>
              {loading ?
              <img src={loadingsvg}></img> :
              error ? <p>{error}</p> :
              <tbody>
                {formData.map((el, i) => (
                  <tr key={i}>
                    <td onClick={() => setSelected(structuredClone(el))}>{el.name}</td>
                  </tr>
                ))}
              </tbody>}
            </Table>
          </div>
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Nom de l'élément</Form.Label>
            <Form.Control value={newData} onChange={e => setNewData(e.target.value)} id="formType-name" type="text" />
          </Form.Group>
          <Button onClick={addHandler} className='mb-3'>Ajouter l'élément</Button>
          {selected ?
          <div>
            <Form.Group className='mb-3'>
              <Form.Label>Nom de l'élément</Form.Label>
              <Form.Control value={selected.name} onChange={e => selectedUpdateHandler(e.target.value)} id="formType-name" type="text" />
            </Form.Group>
            <div className="mb-3"><Button onClick={updateHandler}>Modifier l'élément</Button></div>
            <div className="mb-3"><Button onClick={deleteHandler} variant="danger">Supprimer l'élément</Button></div>
          </div>
          : null}
        </Col>
      </Row>
    </div>
  );
}

export default FormType;