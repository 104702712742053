import RequestHandler from "./RequestHandler";

const Art = {};

Art.Get = async (jwt, aId, success, error) => {
  await RequestHandler.Get(`/Art/${aId}`, {token: jwt}, success, error);
}

Art.GetAll = async (jwt, filter, success, error) => {
  let input = JSON.parse(JSON.stringify(filter));
  input.token = jwt;
  await RequestHandler.Get("/Art", input, success, error);
}

Art.Add = async (jwt, args, success, error) => {
  await RequestHandler.Post("/Art", args, {token: jwt}, success, error);
}

Art.Update = async (jwt, aId, args, success, error) => {
  await RequestHandler.Post("/ArtUpdate", args, {token: jwt, id: aId}, success, error);
}

Art.DeletePictures = async (jwt, aId, args, success = () => {}, error = () => {}) => {
  await RequestHandler.Post("/ArtDeletePictures", args, {token: jwt, id: aId}, success, error);
  return true;
}

Art.UpdatePicture = async (jwt, aId, args, success = () => {}, error = () => {}) => {
  await RequestHandler.Post("/ArtUpdatePicture", args, {token: jwt, id: aId}, success, error);
  return true;
}

Art.AddPicture = async (jwt, aId, args, success = () => {}, error = () => {}) => {
  await RequestHandler.Post("/ArtAddPicture", args, {token: jwt, id: aId}, success, error);
  return true;
}

Art.Delete = async (jwt, aId, success, error) => {
  await RequestHandler.Delete("/Art", {token: jwt, id: aId}, success, error);
}

export default Art;