import { Col, Row, Table, Form, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import User from "../Classes/User";
import loadingsvg from "../img/loading.svg";

function Users(props){

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [users, setUsers] = useState([]);
  let [selected, setSelected] = useState(null);
  let [refresh, setRefresh] = useState(false);

  let [modPassword, setModPassword] = useState("");
  let [modConfirmPassword, setModConfirmPassword] = useState("");

  const username = useRef(null);
  const password = useRef(null);
  const confirm_password = useRef(null);
  const email = useRef(null);
  const status = useRef(null);

  function registerHandler(){
    let fd = new FormData();
    if(username.current.value !== "") fd.append("username", username.current.value);
    if(password.current.value !== "") fd.append("password", password.current.value);
    if(confirm_password.current.value !== "") fd.append("password_confirm", confirm_password.current.value);
    if(email.current.value !== "") fd.append("email", email.current.value);
    fd.append("admin_level", status.current.value);

    User.Add(props.sessionData.jwt, fd, () => {
      setRefresh(!refresh);
    }, () => {})
  }

  function selectionChangeHandler(key, value){
    let tmp = structuredClone(selected);
    tmp[key] = value;
    setSelected(tmp);
  }

  useEffect(() => {
    setLoading(true);
    User.GetAll(props.sessionData.jwt, data => {
      setUsers(data);
      setError(false);
      setLoading(false);
    }, () => {
      setError("Erreur de cgargement des données, veuillez réessayer plus tard.");
      setLoading(false);
    })
  }, [refresh]);

  function getStatus(id){
    switch(Number(id)){
      case 0:
        return "Visiteur";
      case 1:
        return "Gestionnaire";
      case 2:
        return "Administrateur";
      case 3:
        return "Super Administrateur";
      default:
        return "Inconnu";
    }
  }

  function updatePasswordHandler(){
    let fd = new FormData();
    fd.append("username", selected.username);
    if(modPassword !== "") fd.append("password", modPassword);
    if(modConfirmPassword !== "") fd.append("password_confirm", modConfirmPassword);

    User.UpdatePassword(props.sessionData.jwt, fd, () => {
      setRefresh(!refresh);
    }, () => {});
  }

  function updateDataHandler(){
    let fd = new FormData();
    fd.append("username", selected.username);
    if(selected.email !== "") fd.append("email", selected.email);
    fd.append("admin_level", selected.admin_level);
    User.UpdateData(props.sessionData.jwt, fd, () => {
      setRefresh(!refresh);
    }, () => {});
  }

  function deleteHandler(){
    User.Delete(props.sessionData.jwt, selected.id, () => {
      setSelected(null);
      setRefresh(!refresh);
    }, () => {})
  }

  return (
    <Row>
      <Col>
        <Row className="mb-3">
          <div id="user-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nom d'utilisateur</th>
                  <th>Email</th>
                  <th>Statut</th>
                </tr>
              </thead>
              {loading ?
              <img src={loadingsvg}></img> :
              error ? <p>{error}</p> :
              <tbody>
                {users.map((el, i) => (
                  <tr key={i} onClick={() => {setSelected(structuredClone(el)); setModPassword(""); setModConfirmPassword("")}}>
                    <td>{el.username}</td>
                    <td>{el.email}</td>
                    <td>{getStatus(el.admin_level)}</td>
                  </tr>
                ))}
              </tbody>}
            </Table>
          </div>
        </Row>
        <Row>
          {selected ?
            <div>
              <h4>{selected.username}</h4>
              {Number(selected.admin_level) >= Number(props.sessionData.adminRights) ?
              <p>Vous n'avez pas les droits pour modifier les données de cet utilisateur.</p> : 
              <div>
                <Row className="mb-3">
                  <Col>
                    <h5>Nouveau mot de passe</h5>
                    <Form.Group className='mb-3'>
                      <Form.Label>Mot de Passe</Form.Label>
                      <Form.Control value={modPassword} onChange={e => setModPassword(e.target.value)} id="register-password" type="password" placeholder="Mot de passe top secret !" />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Form.Label>Confirmez le mot de Passe</Form.Label>
                      <Form.Control value={modConfirmPassword} onChange={e => setModConfirmPassword(e.target.value)} id="register-confirm-password" type="password" placeholder="Mot de passe top secret !" />
                    </Form.Group>
                    <Button onClick={updatePasswordHandler}>Modifier le mot de passe</Button>
                  </Col>
                  <Col>
                    <h5>Modifer les données</h5>
                    <Form.Group className='mb-3'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control value={selected.email !== null ? selected.email : ""} onChange={e => selectionChangeHandler("email", e.target.value)} id="register-email" type="text" placeholder="nom@abc.de" />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                      <Form.Label>Statut</Form.Label>
                      <Form.Select value={`${selected.admin_level}`} onChange={e => selectionChangeHandler("admin_level", e.target.value)} id="register-status" aria-label="status">
                        <option value="0">Visiteur</option>
                        <option value="1">Gestionnaire</option>
                        {props.sessionData.adminRights === 3 ? <option value="2">Administrateur</option> : null}
                      </Form.Select>
                    </Form.Group>
                    <Button onClick={updateDataHandler}>Modifier les données</Button>
                  </Col>
                </Row>
                <Button onClick={deleteHandler} variant="danger">Supprimer l'utilisateur</Button>
              </div>}
              
            </div>
          : null}
        </Row>
      </Col>
      <Col>
        <h4>Nouvel utilisateur</h4>
        <Form.Group className='mb-3'>
          <Form.Label>Nom d'utilisateur</Form.Label>
          <Form.Control ref={username} id="register-username" type="text" placeholder="Michel" />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Mot de Passe</Form.Label>
          <Form.Control ref={password} id="register-password" type="password" placeholder="Mot de passe top secret !" />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Confirmez le mot de Passe</Form.Label>
          <Form.Control ref={confirm_password} id="register-confirm-password" type="password" placeholder="Mot de passe top secret !" />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Email</Form.Label>
          <Form.Control ref={email} id="register-email" type="text" placeholder="nom@abc.de" />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Statut</Form.Label>
          <Form.Select ref={status} id="register-status" aria-label="status">
            <option value="0">Visiteur</option>
            <option value="1">Gestionnaire</option>
            {props.sessionData.adminRights === 3 ? <option value="2">Administrateur</option> : null}
          </Form.Select>
        </Form.Group>
        <Button onClick={registerHandler}>Enregister</Button>
      </Col>
    </Row>
  );
}

export default Users;