import FormImageSet from "./FormImageSet";

function ConstatPictures(props){
  return (
    <div>
      <FormImageSet readOnly={props.sessionData.adminRights === 0} refresh={props.refresh} type="add" onDeletePicture={props.onDeletePicture} onAddPicture={props.onAddPicture} updateLegend={props.addUpdateLegend} addPictures={props.addPictures} imageSet={props.artData.additional_picture}></FormImageSet>
    </div>
  );
}

export default ConstatPictures;