import { Form, Button, Row, Col } from 'react-bootstrap';
import Utilities from "../Classes/Utilities";

function ArtDonnees(props){
  return (
    <div>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Valeur d'acquisition</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-acqValue" onChange={e => props.updateArtData("acquisition_value", e.target.value)} value={props.artData.acquisition_value} type="text" placeholder="valeur" />
        </Form.Group>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Mode d'acquisition</Form.Label>
          <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-acqMeth"  onChange={e => props.updateArtData("acquisition_method", e.target.value)} value={props.artData.acquisition_method} defaultValue="null" aria-label="acquisition">
            {props.acquisitionData.map((el, i) => <option key={i} value={el}>{el}</option>)}
            <option value="null">Aucun</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Date d'acquisition</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-acdDate" onChange={e => props.updateArtData("acquisition_date", e.target.value)} value={props.artData.acquisition_date} type="date" />
        </Form.Group>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Valeur d'assurance</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-insValue" onChange={e => props.updateArtData("insurance_value", e.target.value)} value={props.artData.insurance_value} type="text" placeholder="valeur" />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Adresse de l'artiste</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-authorAddress" onChange={e => props.updateArtData("author_address", e.target.value)} value={props.artData.author_address} as="textarea" rows={4} placeholder="adresse" />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Nom de l'ancien propriétaire</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-oldLastName" onChange={e => props.updateArtData("old_proprietary_last_name", e.target.value)} value={props.artData.old_proprietary_last_name} type="text" placeholder="nom" />
        </Form.Group>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Prénom de l'ancien propriétaire</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-oldFirstName" onChange={e => props.updateArtData("old_proprietary_first_name", e.target.value)} value={props.artData.old_proprietary_first_name} type="text" placeholder="prénom" />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Adresse de l'ancien propriétaire</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-oldAddress" onChange={e => props.updateArtData("old_proprietary_address", e.target.value)} value={props.artData.old_proprietary_address} as="textarea" rows={4} placeholder="adresse" />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Ayants droits</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-entitled" onChange={e => props.updateArtData("entitled", e.target.value)} value={props.artData.entitled} type="text" placeholder="ayants droits" />
        </Form.Group>
      </Row>
    </div>
  );
}

export default ArtDonnees;