import { useState, useEffect } from 'react';
import { Form, InputGroup, FormControl, Row, Col, Button, Table } from 'react-bootstrap';
import $ from "jquery";
import Art from '../Classes/Art';
import loading from "../img/loading.svg";

const LIMIT = 20;


function Home(props){
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [artData, setArtData] = useState([]);
  const [searchSettings, setSearchSettings] = useState({
    search: "",
    sort: "inventory_number",
    asc: 1,
    limit: LIMIT,
    offset: 0
  });
  const [navigation, setNavigation] = useState({});
  const [pageFreeNav, setPageFreeNav] = useState("");

  function updateFilterHandler(e){
    e.preventDefault();
    let filterParams = {
      search: $("#home-search").val(),
      sort: $("#home-orderby").val(),
      asc: $("#home-orderby-asc").val(),
      limit: LIMIT,
      offset: 0
    }
    setSearchSettings(filterParams);
  }

  function nextHandler(){
    let settings = structuredClone(searchSettings);
    settings.offset += LIMIT;
    setSearchSettings(settings);
  }

  function previousHandler(){
    let settings = structuredClone(searchSettings);
    settings.offset -= LIMIT;
    setSearchSettings(settings);
  }

  function goToHandler(){
    let settings = structuredClone(searchSettings);
    settings.offset = (pageFreeNav-1)*LIMIT;
    setSearchSettings(settings);
  }

  useEffect(() => {
    async function effect(){
      setLoaded(false);
      let display = true;

      await Art.GetAll(
        props.sessionData.jwt, 
        searchSettings,
        data => {
          setArtData(data.data);
          setNavigation({
            currentPage: Math.ceil(data.offset/LIMIT)+1,
            totalPage: Math.ceil(data.total/LIMIT)
          })
        }, 
        () => {
          setError("Erreur de communication avec la base de donnée");
          display = false;
        }
      );

      if(display) {
        setLoaded(true);
      }
    }
    effect();
  }, [searchSettings]);

  return (
    <div className='h-adjust pt-3'>
      <Form onSubmit={updateFilterHandler} className='p-3 m-auto'>
        <Row className='justify-content-center'>
          <Col className='col-3'>
            <InputGroup className="mb-3">
              <InputGroup.Text id="main-search">🔎</InputGroup.Text>
              <FormControl
                id="home-search"
                placeholder='Recherche'
                aria-label='Recherche'
                aria-describedby='main-search'
              />
            </InputGroup>
          </Col>
          <Col className='col-1 d-flex flex-row-reverse'><Form.Label className='pt-1'>Trier par</Form.Label></Col>
          <Col className='col-3'>
            <Form.Select id="home-orderby" defaultValue="inventory_number" aria-label="orderby">
              <option value="inventory_number">Numéro d'inventaire</option>
              <option value="title">Titre</option>
              <option value="author_last_name">Nom de l'artiste</option>
              <option value="author_first_name">Prénom de l'artiste</option>
              <option value="author_pseudonym">Pseudonyme de l'artiste</option>
              <option value="workshop.name">Atelier</option>
              <option value="technique.name">Technique</option>
              <option value="support.name">Support</option>
            </Form.Select>
          </Col>
          <Col className='col-2'>
            <Form.Select id="home-orderby-asc" defaultValue="1" aria-label="orderby-asc">
              <option value="1">Croissant</option>
              <option value="0">Décroissant</option>
            </Form.Select>
          </Col>
          <Col className='col-1'>
            <Button onClick={updateFilterHandler} variant="primary">Appliquer</Button>
          </Col>
        </Row>
      </Form>
      <div className='p-3 m-auto text-center'>
        <Row className='justify-content-center'>
          <Col className='col-2'>
            <Button onClick={previousHandler} disabled={navigation.currentPage <= 1}>Précédent</Button>
            <span className='p-3'>{navigation.currentPage} / {navigation.totalPage}</span>
            <Button onClick={nextHandler} disabled={navigation.currentPage >= navigation.totalPage}>Suivant</Button>
          </Col>
          <Col className='col-1'>
            <Form.Label className='pt-1'>Aller à la page</Form.Label>
          </Col>
          <Col className='col-2'>
            <Form.Control value={pageFreeNav} onChange={e => setPageFreeNav(e.target.value)} id="select-page" type="number" placeholder="page" />
          </Col>
          <Col className='col-1'>
            <Button onClick={goToHandler} disabled={isNaN(pageFreeNav) || pageFreeNav < 1 || pageFreeNav > navigation.totalPage}>Confirmer</Button>
          </Col>
        </Row>
      </div>
      <div className='p-3 m-auto d-flex justify-content-center'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>N° Inventaire</th>
              <th>Titre</th>
              <th>Nom artiste</th>
              <th>Prénom artiste</th>
              <th>Pseudonyme artiste</th>
              <th>Atelier</th>
              <th>Technique</th>
              <th>Support</th>
              <th>Actions</th>
            </tr>
          </thead>
          {loaded ? 
          <tbody>
            {artData.map(art => (
              <tr key={art.id}>
                <td onClick={() => props.onArt(art.id)}>{art.inventory_number}</td>
                <td onClick={() => props.onArt(art.id)}>{art.title}</td>
                <td onClick={() => props.onArt(art.id)}>{art.author_last_name}</td>
                <td onClick={() => props.onArt(art.id)}>{art.author_first_name}</td>
                <td onClick={() => props.onArt(art.id)}>{art.author_pseudo}</td>
                <td onClick={() => props.onArt(art.id)}>{art.workshop}</td>
                <td onClick={() => props.onArt(art.id)}>{art.technique}</td>
                <td onClick={() => props.onArt(art.id)}>{art.support}</td>
                <td>{props.tags.some(e => e.id === art.id) ? <Button onClick={() => props.onTagRemove(art.id)} className="tag-btn" variant="danger">- 🏷️</Button> : <Button className="tag-btn" onClick={() => props.onTagAdd(structuredClone(art))}>+ 🏷️</Button>}</td>
              </tr>
            ))}
          </tbody> :
          error === "" ? <thead><tr><td><img alt="loading-image" className="page-loading" src={loading}></img></td></tr></thead> : <thead><tr><td>{error}</td></tr></thead>}
        </Table>
      </div>
    </div>
  );
}

export default Home;