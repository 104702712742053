import { useState } from "react";
import Switch from "react-switch";

function ReactSwitch(props){
  const [checked, setChecked] = useState(props.defaultValue);
  function handleChange(checked){
    setChecked(checked);
  }
  return (
    <Switch disabled={props.readOnly} id={props.id} onColor="#0d6efd" onChange={(checked) => {handleChange(checked); props.onChange(checked);}} checked={checked} />
  );
}

ReactSwitch.defaultProps = {
  onChange : () => {},
  defaultValue : false,
  id: "",
  readOnly: false
}

export default ReactSwitch;