import { useState } from "react";
import Forms from "./Forms";
import Router from "./Router";
import Users from "./Users";
import Suppliers from "./Suppliers";

function Admin(props){
  let [tab, setTab] = useState("forms");

  return (
    <div className='h-adjust p-3'>
      <div className="main-panel">
        <button onClick={() => setTab("forms")} className={`tab${tab === "forms" ? " tab-active" : ""}`}>Formulaires</button>
        <button onClick={() => setTab("suppliers")} className={`tab${tab === "suppliers" ? " tab-active" : ""}`}>Fournisseurs</button>
        {props.sessionData.adminRights >= 2 ? <button onClick={() => setTab("users")} className={`tab${tab === "users" ? " tab-active" : ""}`}>Utilisateurs</button> : null}
        <div className="tab-content p-3 rounded">
          <Router currentRoute={tab}>
            <Users route-id="users" sessionData={props.sessionData} />
            <Forms route-id="forms" sessionData={props.sessionData} />
            <Suppliers route-id="suppliers" sessionData={props.sessionData} />
          </Router>
        </div>
      </div>
    </div>
  );
}

export default Admin;