import RequestHandler from "./RequestHandler";

const Supplier = {};

Supplier.Get = async (jwt, sId, success, error) => {
  await RequestHandler.Get(`/Supplier/${sId}`, {token: jwt}, success, error);
}

Supplier.GetAll = async (jwt, success, error) => {
  await RequestHandler.Get("/Supplier", {token: jwt}, success, error);
}

Supplier.Add = async (jwt, args, success, error) => {
  await RequestHandler.Post("/Supplier/add", args, {token: jwt}, success, error);
}

Supplier.Update = async (jwt, args, success, error) => {
  await RequestHandler.Post("/Supplier/update", args, {token: jwt}, success, error);
}

Supplier.Delete = async (jwt, sId, success, error) => {
  await RequestHandler.Delete("/Supplier", {token: jwt, id: sId}, success, error);
}

Supplier.GetByProducts = async (jwt, args, success, error) => {
  await RequestHandler.Post("/SupplierBP", args, {token : jwt}, success, error);
}

export default Supplier;