import RequestHandler from "./RequestHandler";

let loggedin = false;
let adminRights = false;
let username = "";
let jwt = "";

const Session = {};

Session.Connect = async (login, pwd, success = () => {}, error = () => {}) => {
  //Contact the API
  let dbSuccess = true;
  let errorMessage = "";

  let fd = new FormData();
  fd.append("username", login);
  fd.append("password", pwd);
  
  await RequestHandler.Post("/Login", fd, {}, data => {
    if(data.status === 200){
      loggedin = true;
      username = login;
      adminRights = Number(data.admin);
      jwt = data.token;
    }
    else dbSuccess = false;
  }, error => { 
    errorMessage = error.response.data.errors[0];
    dbSuccess = false; 
  });
  
  if(dbSuccess) success();
  else error(errorMessage);
}

Session.Disconnect = () => {
  loggedin = false;
  adminRights = false;
  username = "";
  jwt = "";
}

Session.getSession = () => {
  return {
    loggedin: loggedin,
    adminRights: adminRights,
    username: username,
    jwt: jwt
  };
}

export default Session;