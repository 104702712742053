import { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Router from './Router';
import Utilities from "../Classes/Utilities";
import ConstatStatus from './ConstatStatus';
import ConstatPackaging from './ConstatPackaging';
import ConstatIntervention from './ConstatIntervention';
import ConstatPictures from './ConstatPictures';
import ConstatAnnex from './ConstatAnnex';

function ArtConstat(props){
  const [tab, setTab] = useState("status");
  function updateMap(mapName, value){
    let arraycp = structuredClone(props.artData[mapName]);
    if(arraycp.includes(value)){
      arraycp = Utilities.RemoveFromArray(arraycp, value);
    }
    else{
      arraycp.push(value);
    }
    props.updateArtData(mapName, arraycp);
  }
  return (
    <div>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Contrôle effectué par</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-controledBy" onChange={e => props.updateArtData("controled_by", e.target.value)} value={props.artData.controled_by} type="text" placeholder="prénom et nom" />
        </Form.Group>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Date du contrôle</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-controlDate" onChange={e => props.updateArtData("control_date", e.target.value)} value={props.artData.control_date} type="date" placeholder="jj/mm/aaaa" />
        </Form.Group>
      </Row>
      <button onClick={() => setTab("status")} className={`inner-tab${tab === "status" ? " active-inner-tab" : ""}`}>État de conservation</button>
      <button onClick={() => setTab("packaging")} className={`inner-tab${tab === "packaging" ? " active-inner-tab" : ""}`}>Emballage</button>
      <button onClick={() => setTab("intervention")} className={`inner-tab${tab === "intervention" ? " active-inner-tab" : ""}`}>Intervention d'urgence</button>
      <button onClick={() => setTab("pictures")} className={`inner-tab${tab === "pictures" ? " active-inner-tab" : ""}`}>Photos supplémentaires</button>
      <button onClick={() => setTab("annex")} className={`inner-tab${tab === "annex" ? " active-inner-tab" : ""}`}>Informations annexes</button>
      <div className="inner-tab-content p-3 rounded">
        <Router currentRoute={tab}>
          <ConstatStatus 
            route-id="status" 
            artData={props.artData}
            updateArtData={props.updateArtData}
            updateMap={updateMap}
            supportStatusData={props.supportStatusData}
            surfaceStatusData={props.surfaceStatusData}
            generalStatusData={props.generalStatusData}
            sessionData={props.sessionData}
          />
          <ConstatPackaging 
            route-id="packaging" 
            artData={props.artData}
            updateArtData={props.updateArtData}
            updateMap={updateMap}
            packagingData={props.packagingData}
            sessionData= {props.sessionData}
          />
          <ConstatIntervention 
            route-id="intervention" 
            artData={props.artData}
            updateArtData={props.updateArtData}
            updateMap={updateMap}
            preservationData={props.preservationData}
            restorationData={props.restorationData}
            sessionData={props.sessionData}
          />
          <ConstatPictures
            route-id="pictures" 
            artData={props.artData}
            updateArtData={props.updateArtData}
            onAddPicture={props.addPictureChangeHandler}
            addPictures={props.addAddedImages}
            addUpdateLegend={props.addUpdateLegend}
            onDeletePicture={props.onDeletePicture}
            refresh={props.refresh}
            sessionData={props.sessionData}
          />
          <ConstatAnnex 
            route-id="annex" 
            artData={props.artData}
            updateArtData={props.updateArtData}
            onAddPicture={props.sidePictureChangeHandler}
            addPictures={props.sideAddedImages}
            sideUpdateLegend={props.sideUpdateLegend}
            onDeletePicture={props.onDeletePicture}
            refresh={props.refresh}
            sessionData={props.sessionData}
          />
        </Router>
      </div>
    </div>
  );
}

export default ArtConstat;