import { useState, useEffect } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import Supplier from "../Classes/Supplier";
import loadingsvg from "../img/loading.svg";

function ConstatPackaging(props){

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [supplierData, setSupplierData] = useState([]);

  useEffect(() => {
    setLoading(true);
    let fd = new FormData();
    for(let el of props.artData.packaging){
      fd.append("products[]", el);
    }
    Supplier.GetByProducts(props.sessionData.jwt, fd, async data => {
      setSupplierData(data);
      setError(false);
      setLoading(false);
    }, () => {
      setError("Erreur de récupération des données.");
      setLoading(false);
    });
  }, [props.artData.packaging]);

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <Form.Label>Emballage</Form.Label>
          {props.packagingData.map((e, i) => 
            <Form.Check disabled={props.sessionData.adminRights === 0} type="checkbox" label={e} key={i} onChange={() => props.updateMap("packaging", e)} checked={props.artData.packaging.includes(e)} />
          )}
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Exigences et recommandations</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-demands" onChange={e => props.updateArtData("demands", e.target.value)} value={props.artData.demands} as="textarea" rows={4} placeholder="exigences et recommandations" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Label>Fournisseurs</Form.Label>
        <div className="supplier-table">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Produit</th>
                <th>Nom</th>
                <th>Adresse postale</th>
                <th>Adresse mail</th>
                <th>Téléphone</th>
                <th>Site web</th>
              </tr>
            </thead>

            {loading ? 
            <img src={loadingsvg} className="form-loading"></img> :
            error ? 
            <tbody><tr><td>{error}</td></tr></tbody> : 
            <tbody>
              {supplierData.map((supplier, i) => (
                <tr key={i}>
                  <td>{supplier.product}</td>
                  <td>{supplier.name}</td>
                  <td>{supplier.address}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phone_number}</td>
                  <td>{supplier.website}</td>
                </tr>
              ))}
            </tbody>
            }
          </Table>
        </div>
      </Row>
    </div>
  );
}

export default ConstatPackaging;