import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Utilities from "../Classes/Utilities";

function FormImageSet(props){

  let [imgSelected, setImgSelected] = useState(null);

  function deleteHandler(){
    setImgSelected(null);
    props.onDeletePicture(props.type, imgSelected.path);
  }

  function addPictureHandler(e){
    setImgSelected(null);
    props.onAddPicture(e);
  }

  useEffect(() => {
    setImgSelected(null);
  }, [props.refresh]);

  return (
    <div id="image-set" className="mb-3">
      <div id="image-container">
        {props.imageSet.map((el, i) => 
          <img src={Utilities.GetDistantImage(el.path)} key={i} onClick={() => setImgSelected(el)} className="thumbnail"></img>
        )}
        {props.addPictures ? 
        props.addPictures.map((el, i) => <img src={el.path} key={i} onClick={() => setImgSelected(el)} className="thumbnail"></img>)
        : null}
      </div>
      <div id="image-controller">
        <div>
          {imgSelected ?
            <div className="mb-3">
              <div className="mb-3 image-placehodler">
                <img src={imgSelected.path.startsWith("blob") ? imgSelected.path : Utilities.GetDistantImage(imgSelected.path)} className="img-contain" />
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Légende</Form.Label>
                <Form.Control readOnly={props.readOnly} value={!imgSelected.path.startsWith("blob") ? (props.imageSet.find(el => el.path === imgSelected.path).legend ?? "") : (props.addPictures.find(el => el.path === imgSelected.path).legend ?? "")} onChange={e => props.updateLegend(imgSelected.path, e.target.value)} className="updateArt-imgLegend" type="text" placeholder="légende" />
              </Form.Group>
              <Button disabled={props.readOnly} variant="danger" onClick={deleteHandler}>Supprimer l'image</Button>
            </div> : 
            <div className="mb-3">
              <div className="mb-3 image-placehodler">
                <img src={imgSelected} className="img-contain" />
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Légende</Form.Label>
                <Form.Control disabled value="" className="updateArt-imgLegend" type="text" placeholder="légende" />
              </Form.Group>
              <Button disabled variant="danger">Supprimer l'image</Button>
            </div>
          }
          <Form.Group className='mb-3'>
            <Form.Label>Ajouter</Form.Label>
            <Form.Control disabled={props.readOnly} id="modifyArt-image" onChange={addPictureHandler} type="file" accept="image/png, image/gif, image/jpeg" multiple />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default FormImageSet;