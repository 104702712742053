function Router({currentRoute, children}){
  let childToBeRendered = null;
  if(Object.prototype.toString.call(children) === '[object Array]'){
    for(let child of children){
      if(child.props["route-id"] === currentRoute){
        childToBeRendered = child;
        break;
      }
    }
  }
  else childToBeRendered = children;
  return childToBeRendered;
}

export default Router;