import axios from "axios";
import config from "../config";
const apiPath = config.apiPath;

function getUrlArgs(args){
  let data = [];
  for(let [key, value] of Object.entries(args)){
    data.push(`${key}=${value}`);
  }
  return `?${data.join("&")}`;
}

async function Post(path, args, urlArgs, success, error){
  try{
    let response = await axios.post(apiPath + path + getUrlArgs(urlArgs), args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    success(response.data);
  }
  catch(errorObject){
    error(errorObject);
  }
}

async function Get(path, args, success, error){
  try{
    let response = await axios.get(apiPath + path + getUrlArgs(args));
    success(response.data);
  }
  catch(errorObject){
    error(errorObject);
  }
}

async function Put(path, args, urlArgs, success, error){
  try{
    let params = new URLSearchParams();
    for(let key in args){
      params.append(key, args[key]);
    }
    let response = await axios.put(apiPath + path + getUrlArgs(urlArgs), params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    success(response.data);
  }
  catch(errorObject){
    error(errorObject);
  }
}

async function Delete(path, args, success, error){
  try{
    let response = await axios.delete(apiPath + path + getUrlArgs(args));
    success(response.data);
  }
  catch(errorObject){
    error(errorObject);
  }
}

const RequestHandler = {
  Post: Post,
  Get: Get,
  Put: Put,
  Delete: Delete
};

export default RequestHandler;