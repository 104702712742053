import { useState, useEffect } from "react";
import Supplier from "../Classes/Supplier";
import RequestHandler from "../Classes/RequestHandler";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import Utilities from "../Classes/Utilities";
import loadingsvg from "../img/loading.svg";

function SupplierList(props){

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [suppliers, setSuppliers] = useState([]);
  let [packagingData, setPackagingData] = useState([]);
  let [selected, setSelected] = useState(null);
  let [refresh, setRefresh] = useState(false);

  function selectHandler(id){
    Supplier.Get(props.sessionData.jwt, id, data => {
      setSelected(data);
    }, () => {
      setError("Erreur de chargement des données, veuillez réessayer plus tard.");
    })
  }

  function selectionChangeHandler(key, value){
    let tmp = structuredClone(selected);
    tmp[key] = value;
    setSelected(tmp);
  }

  function updateProductsHandler(product){
    let tmp = structuredClone(selected);
    if(tmp.products.includes(product))
      tmp.products = Utilities.RemoveFromArray(tmp.products, product);
    else
      tmp.products.push(product);
    setSelected(tmp);
  }

  function updateDataHandler(){
    let fd = new FormData();
    for(let [key, value] of Object.entries(selected)){
      if(key === "products"){
        for(let val of selected["products"]){
          fd.append("products[]", val);
        }
      }
      else fd.append(key, value);
      console.log(value);
    }
    
    Supplier.Update(props.sessionData.jwt, fd, () => {
      selectHandler(selected.id);
      setRefresh(!refresh);
    }, () => {});
  }

  function deleteDataHandler(){
    Supplier.Delete(props.sessionData.jwt, selected.id, () => {
      setSelected(null);
      setRefresh(!refresh);
    }, () => {});
  }

  useEffect(() => {
    async function effect(){
      await Supplier.GetAll(props.sessionData.jwt, data => {
        setSuppliers(data);
        setError(false);
        setLoading(false);
      }, () => {
        setError("Erreur de chargement des données, veuillez réessayer plus tard.");
      });
      await RequestHandler.Get("/Packaging", {token: props.sessionData.jwt}, 
      data => setPackagingData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
      });
      setLoading(false);
    }
    setLoading(true);
    effect();
  }, [refresh]);

  return (
    <div>
      <Row>
        <Col>
          <div id="user-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nom</th>
                </tr>
              </thead>
              {loading ?
              <img src={loadingsvg}></img> :
              error ? <p>{error}</p> :
              <tbody>
                {suppliers.map((el, i) => (
                  <tr onClick={() => selectHandler(el.id)} key={i}><td>{el.name}</td></tr>
                ))}
              </tbody>}
            </Table>
          </div>
        </Col>
        <Col>
          {
            selected ? 
            <div>
              <h5>{selected.name}</h5>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control value={selected.name !== null ? selected.name : ""} onChange={e => selectionChangeHandler("name", e.target.value)} type="text" placeholder="Nom du fournisseur" />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={selected.email !== null ? selected.email : ""} onChange={e => selectionChangeHandler("email", e.target.value)} type="text" placeholder="nom@abc.de" />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Numéro de téléphone</Form.Label>
                    <Form.Control value={selected.phone_number !== null ? selected.phone_number : ""} onChange={e => selectionChangeHandler("phone_number", e.target.value)} type="text" placeholder="04 123 45 67" />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control value={selected.address !== null ? selected.address : ""} onChange={e => selectionChangeHandler("address", e.target.value)} type="text" placeholder="Adresse postale" />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Site web</Form.Label>
                    <Form.Control value={selected.website !== null ? selected.website : ""} onChange={e => selectionChangeHandler("website", e.target.value)} type="text" placeholder="www.fournisseur.be" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Produits</Form.Label>
                  {packagingData.map((e, i) => 
                    <Form.Check type="checkbox" label={e} key={i} onChange={() => updateProductsHandler(e)} checked={selected.products.includes(e)} />
                  )}
                </Col>
              </Row>
              <div className="ops-supplier">
                <Button className="mr-3" onClick={updateDataHandler}>Modifier les données</Button>
                <Button variant="danger" onClick={deleteDataHandler}>Supprimer le fournisseur</Button>
              </div>
            </div> :
            null
          }
        </Col>
      </Row>
    </div>
  )
}

export default SupplierList;