import { useState, useEffect } from "react";
import Supplier from "../Classes/Supplier";
import RequestHandler from "../Classes/RequestHandler";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import Utilities from "../Classes/Utilities";
import loadingsvg from "../img/loading.svg";

function SupplierNew(props){

  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [packagingData, setPackagingData] = useState([]);
  let [selected, setSelected] = useState({
    name : "",
    email: "",
    phone_number : "",
    address : "",
    website: "",
    products: []
  });
  let [refresh, setRefresh] = useState(false);

  function selectionChangeHandler(key, value){
    let tmp = structuredClone(selected);
    tmp[key] = value;
    setSelected(tmp);
  }

  function updateProductsHandler(product){
    let tmp = structuredClone(selected);
    if(tmp.products.includes(product))
      tmp.products = Utilities.RemoveFromArray(tmp.products, product);
    else
      tmp.products.push(product);
    setSelected(tmp);
  }

  function addHandler(){
    let fd = new FormData();
    for(const key in selected){
      if(key === "products"){
        for(let val of selected["products"]){
          fd.append("products[]", val);
        }
      }
      else fd.append(key, selected[key]);
    }
    Supplier.Add(props.sessionData.jwt, fd, () => {
      props.onSuccess("list");
    }, () => {});
  }

  useEffect(() => {
    async function effect(){
      await RequestHandler.Get("/Packaging", {token: props.sessionData.jwt}, 
      data => setPackagingData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
      });
      setLoading(false);
    }
    setLoading(true);
    effect();
  }, [refresh]);

  return (
    <div>
      <h5>Nouveau fournisseur</h5>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Nom</Form.Label>
            <Form.Control value={selected.name !== null ? selected.name : ""} onChange={e => selectionChangeHandler("name", e.target.value)} type="text" placeholder="Nom du fournisseur" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Email</Form.Label>
            <Form.Control value={selected.email !== null ? selected.email : ""} onChange={e => selectionChangeHandler("email", e.target.value)} type="text" placeholder="nom@abc.de" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control value={selected.phone_number !== null ? selected.phone_number : ""} onChange={e => selectionChangeHandler("phone_number", e.target.value)} type="text" placeholder="04 123 45 67" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Adresse</Form.Label>
            <Form.Control value={selected.address !== null ? selected.address : ""} onChange={e => selectionChangeHandler("address", e.target.value)} type="text" placeholder="Adresse postale" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Site web</Form.Label>
            <Form.Control value={selected.website !== null ? selected.website : ""} onChange={e => selectionChangeHandler("website", e.target.value)} type="text" placeholder="www.fournisseur.be" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Label>Produits</Form.Label>
          {loading ?
          <img src={loadingsvg}></img> :
          error ? <p>{error}</p> :
          packagingData.map((e, i) => 
            <Form.Check type="checkbox" label={e} key={i} onChange={() => updateProductsHandler(e)} />
          )}
        </Col>
      </Row>
      <Button onClick={addHandler}>Ajouter le fournisseur</Button>
    </div>
  )
}

export default SupplierNew;