import { Form, Row, Col } from "react-bootstrap";
import ReactSwitch from "./ReactSwitch";

function ConstatStatus(props){
  return (
    <div>
      <Row className="mb-2">
        <Col>
          <Form.Label>Support</Form.Label>
          {props.supportStatusData.map((e, i) => 
            <Form.Check disabled={props.sessionData.adminRights === 0} type="checkbox" label={e} key={i} onChange={() => props.updateMap("support_status", e)} checked={props.artData.support_status.includes(e)} />
          )}
        </Col>
        <Col>
          <Form.Label>Surface</Form.Label>
          {props.surfaceStatusData.map((e, i) => 
            <Form.Check disabled={props.sessionData.adminRights === 0} type="checkbox" label={e} key={i} onChange={() => props.updateMap("surface_status", e)} checked={props.artData.surface_status.includes(e)} />
          )}
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Dossier de restauration</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-restorationDossier" onChange={e => props.updateArtData("restoration_dossier", e.target.value)} value={props.artData.restoration_dossier} type="text" placeholder="" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>État général</Form.Label>
            <Form.Select disabled={props.sessionData.adminRights === 0} id="modifyArt-generalStatus" onChange={e => props.updateArtData("general_status", e.target.value)} value={props.artData.general_status} defaultValue="null" aria-label="generalStatus">
              {props.generalStatusData.map((el, i) => <option key={i} value={el}>{el}</option>)}
              <option value="null">Aucun</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Transportable</Form.Label>
            <br />
            <ReactSwitch readOnly={props.sessionData.adminRights === 0} onChange={e => props.updateArtData("portable", e ? "1" : "0")} defaultValue={props.artData.portable == "1"} id="modifyArt-portable" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Remarques</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-notes" onChange={e => props.updateArtData("notes", e.target.value)} value={props.artData.notes} as="textarea" rows={4} placeholder="remarques" />
        </Form.Group>
      </Row>
    </div>
  );
}

export default ConstatStatus;