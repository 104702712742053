import { useState, useEffect } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import Art from '../Classes/Art';
import loading from "../img/loading.svg";
import RequestHandler from "../Classes/RequestHandler";
import Utilities from '../Classes/Utilities';
import Router from './Router';
import ArtConstat from "./ArtConstat";
import ArtDonnees from "./ArtDonnees";
import ArtEmplacement from "./ArtEmplacement";
import ArtIdentification from "./ArtIdentification";
import ArtJournal from "./ArtJournal";

function ModifyArt(props){
  const [workshopData, setWorkshopData] = useState([]);
  const [techniqueData, setTechniqueData] = useState([]);
  const [supportData, setSupportData] = useState([]);
  const [nationalityData, setNationalityData] = useState([]);
  const [acquisitionData, setAcquisitionData] = useState([]);
  const [surfaceStatusData, setSurfaceStatusData] = useState([]);
  const [supportStatusData, setSupportStatusData] = useState([]);
  const [generalStatusData, setGeneralStatusData] = useState([]);
  const [packagingData, setPackagingData] = useState([]);
  const [preservationData, setPreservationData] = useState([]);
  const [restorationData, setRestorationData] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [studyYearData, setStudyYearData] = useState([]);
  const [image, setImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [opStatus, setOpStatus] = useState(false);
  const [artData, setArtData] = useState([]);
  const [tab, setTab] = useState("identification");
  const [printData, setPrintData] = useState(null);
  const [addAddedImages, setAddAddedImages] = useState(null);
  const [sideAddedImages, setSideAddedImages] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {

    async function effect(){
      let display = true;

      setAddAddedImages(null);
      setSideAddedImages(null);

      await RequestHandler.Get("/Workshop", {token: props.sessionData.jwt}, 
      data => setWorkshopData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Technique", {token: props.sessionData.jwt}, 
      data => setTechniqueData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Support", {token: props.sessionData.jwt}, 
      data => setSupportData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Nationality", {token: props.sessionData.jwt}, 
      data => setNationalityData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Acquisition", {token: props.sessionData.jwt}, 
      data => setAcquisitionData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/SupportStatus", {token: props.sessionData.jwt}, 
      data => setSupportStatusData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/SurfaceStatus", {token: props.sessionData.jwt}, 
      data => setSurfaceStatusData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/GeneralStatus", {token: props.sessionData.jwt}, 
      data => setGeneralStatusData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Packaging", {token: props.sessionData.jwt}, 
      data => setPackagingData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Preservation", {token: props.sessionData.jwt}, 
      data => setPreservationData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Restoration", {token: props.sessionData.jwt}, 
      data => setRestorationData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/Weight", {token: props.sessionData.jwt}, 
      data => setWeightData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await RequestHandler.Get("/StudyYear", {token: props.sessionData.jwt}, 
      data => setStudyYearData(data.map(el => el.name)), 
      () => {
        setError("Erreur de communication avec la base de donnée");
        display = false;
      });

      await Art.Get(
        props.sessionData.jwt, 
        props.artId,
        data => {      
          data.art_creation_date = Utilities.DateToYear(data.art_creation_date);
          
          setArtData(data);
          setPrintData(structuredClone(data));
        },
        () => {
          setError("Erreur de communication avec la base de donnée");
          display = false;
        }
      );

      if(display) {
        setLoaded(true);
      }
    }
    effect();  
  }, [refresh]);

  function deletePicture(type, path){
    let newArray = [];
    for(let obj of (path.startsWith("blob") ? (type === "add" ? addAddedImages : sideAddedImages) : (type === "add" ? artData.additional_picture : artData.side_picture))){
      if(obj.path !== path){
        newArray.push(obj);
      }
    }
    path.startsWith("blob") ? (type === "add" ? setAddAddedImages(newArray) : setSideAddedImages(newArray)) : updateArtData(type === "add" ? "additional_picture" : "side_picture", newArray);
  }

  function addPictureChangeHandler(e){
    let readers = [];
    for(let file of e.target.files){
      readers.push({
        path: URL.createObjectURL(file),
        legend: ""
      });
    }
    setAddAddedImages(readers);
  }

  function sidePictureChangeHandler(e){
    let readers = [];
    for(let file of e.target.files){
      readers.push({
        path: URL.createObjectURL(file),
        legend: ""
      });
    }
    setSideAddedImages(readers);
  }

  function addUpdateLegend(imagePath, value){
    if(!imagePath.startsWith("blob")){
      let imgs = structuredClone(artData.additional_picture);
      let [id, object] = Utilities.Find(imgs, "path", imagePath);
      if(id || id === 0){
        object.legend = value;
        imgs[id] = object;
        updateArtData("additional_picture", imgs);
      }
    }
    else{
      let imgs = structuredClone(addAddedImages);
      let [id, object] = Utilities.Find(imgs, "path", imagePath);
      if(id ||id === 0){
        object.legend = value;
        imgs[id] = object;
        setAddAddedImages(imgs);
      }
    }
  }

  function sideUpdateLegend(imagePath, value){
    if(!imagePath.startsWith("blob")){
      let imgs = structuredClone(artData.side_picture);
      let [id, object] = Utilities.Find(imgs, "path", imagePath);
      if(id || id === 0){
        object.legend = value;
        imgs[id] = object;
        updateArtData("side_picture", imgs);
      }
    }
    else{
      let imgs = structuredClone(sideAddedImages);
      let [id, object] = Utilities.Find(imgs, "path", imagePath);
      if(id || id === 0){
        object.legend = value;
        imgs[id] = object;
        setSideAddedImages(imgs);
      }
    }
  }

  function pictureChangeHandler(e){
    let path = URL.createObjectURL(e.target.files[0]);
    setImage(path);
  }

  function updateArtData(key, value){
    let datacp = structuredClone(artData);
    datacp[key] = value;
    setArtData(datacp);
  }

  function printHandler(){
    window.print();
  }

  async function updateHandler(){
    setOpStatus(true);
    if(artData.inventory_number === ""){
      setOpStatus(false);
      return setError("Le numéro d'inventaire ne peux pas être vide.");
    }
    if(isNaN(artData.number)){
      setOpStatus(false);
      return setError("La nombre d'exemplaire doit être un nombre.");
    }
    if(isNaN(artData.height)){
      setOpStatus(false);
      return setError("La hauteur doit être un nombre.");
    }
    if(isNaN(artData.width)){
      setOpStatus(false);
      return setError("La largeur doit être un nombre.");
    }
    if(isNaN(artData.depth)){
      setOpStatus(false);
      return setError("La profondeur doit être un nombre.");
    }
    if(isNaN(artData.diameter)){
      setOpStatus(false);
      return setError("Le diamètre doit être un nombre.");
    }
    if(isNaN(artData.art_creation_date)){
      setOpStatus(false);
      return setError("L'année de création doit être un nombre.")
    }

    let artCretDate = new Date(artData.art_creation_date);
    let strDate = `${artCretDate.getFullYear()}-${artCretDate.getMonth()+1}-${artCretDate.getDate()}`;

    let fd = new FormData();

    if(artData.author_address && artData.author_address !== "") fd.append("author_address", artData.author_address);
    if(artData.old_proprietary_address && artData.old_proprietary_address !== "") fd.append("old_proprietary_address", artData.old_proprietary_address);
    if(artData.workshop && artData.workshop !== "null") fd.append("workshop", artData.workshop);
    if(artData.technique && artData.technique !== "null") fd.append("technique", artData.technique);
    if(artData.support && artData.support !== "null") fd.append("support", artData.support);
    if(artData.nationality && artData.nationality !== "null") fd.append("nationality", artData.nationality);
    if(artData.weight && artData.weight !== "null") fd.append("weight", artData.weight);
    if(artData.study_year && artData.study_year !== "null") fd.append("study_year", artData.study_year);
    if(artData.general_status && artData.general_status !== "null") fd.append("general_status", artData.general_status);
    if(artData.acquisition_method && artData.acquisition_method !== "null") fd.append("acquisition_method", artData.acquisition_method);
    if(artData.author_last_name && artData.author_last_name !== "") fd.append("author_last_name", artData.author_last_name);
    if(artData.author_first_name && artData.author_first_name !== "") fd.append("author_first_name", artData.author_first_name);
    if(artData.author_pseudo && artData.author_pseudo !== "") fd.append("author_pseudo", artData.author_pseudo);
    if(artData.birth_date && artData.birth_date !== "") fd.append("birth_date", artData.birth_date);
    if(artData.death_date && artData.death_date !== "") fd.append("death_date", artData.death_date);
    if(artData.old_proprietary_last_name && artData.old_proprietary_last_name !== "") fd.append("old_proprietary_last_name", artData.old_proprietary_last_name);
    if(artData.old_proprietary_first_name && artData.old_proprietary_first_name !== "") fd.append("old_proprietary_first_name", artData.old_proprietary_first_name);
    if(artData.inventory_number && artData.inventory_number !== "") fd.append("inventory_number", artData.inventory_number);
    if(artData.old_inventory_number && artData.old_inventory_number !== "") fd.append("old_inventory_number", artData.old_inventory_number);
    if(artData.title && artData.title !== "") fd.append("title", artData.title);
    if(artData.number && artData.number !== "") fd.append("number", artData.number);
    if(artData.height && artData.height !== "") fd.append("height", artData.height);
    if(artData.width && artData.width !== "") fd.append("width", artData.width);
    if(artData.depth && artData.depth !== "") fd.append("depth", artData.depth);
    if(artData.diameter && artData.diameter !== "") fd.append("diameter", artData.diameter);
    if(artData.base_framing && artData.base_framing !== "") fd.append("base_framing", artData.base_framing);
    if(artData.description && artData.description !== "") fd.append("description", artData.description);
    if(artData.art_creation_date && artData.art_creation_date !== "") fd.append("art_creation_date", strDate);
    fd.append("signed", artData.signed);
    if(artData.signature_location && artData.signature_location !== "") fd.append("signature_location", artData.signature_location);
    if(artData.usual_location && artData.usual_location !== "") fd.append("usual_location", artData.usual_location);
    if(artData.location && artData.location !== "") fd.append("location", artData.location);
    if(artData.ancient_location && artData.ancient_location !== "") fd.append("ancient_location", artData.ancient_location);
    if(artData.location_reason && artData.location_reason !== "") fd.append("location_reason", artData.location_reason);
    if(artData.leave_day && artData.leave_day !== "") fd.append("leave_day", artData.leave_day);
    if(artData.return_day && artData.return_day !== "") fd.append("return_day", artData.return_day);
    if(artData.location_historic && artData.location_historic !== "") fd.append("location_historic", artData.location_historic);
    if(artData.acquisition_value && artData.acquisition_value !== "") fd.append("acquisition_value", artData.acquisition_value);
    if(artData.acquisition_date && artData.acquisition_date !== "") fd.append("acquisition_date", artData.acquisition_date);
    if(artData.insurance_value && artData.insurance_value !== "") fd.append("insurance_value", artData.insurance_value);
    if(artData.entitled && artData.entitled !== "") fd.append("entitled", artData.entitled);
    if(artData.notes && artData.notes !== "") fd.append("notes", artData.notes);
    if(artData.restoration_dossier && artData.restoration_dossier !== "") fd.append("restoration_dossier", artData.restoration_dossier);
    fd.append("portable", artData.portable);
    if(artData.controled_by && artData.controled_by !== "") fd.append("controled_by", artData.controled_by);
    if(artData.control_date && artData.control_date !== "") fd.append("control_date", artData.control_date);
    if(artData.demands && artData.demands !== "") fd.append("demands", artData.demands);
    if(artData.art_movement && artData.art_movement !== "") fd.append("art_movement", artData.art_movement);
    if(artData.intervention_notes && artData.intervention_notes !== "") fd.append("intervention_notes", artData.intervention_notes);
    if(artData.side_info && artData.side_info !== "") fd.append("side_info", artData.side_info);
    if(artData.emergency_plan && artData.emergency_plan !== "") fd.append("emergency_plan", artData.emergency_plan);

    if(image !== ""){
      fd.append("main_picture", await Utilities.GetBlob(image));
    }

    for(let el of artData.preservation){
      fd.append("preservation[]", el);
    }

    for(let el of artData.restoration){
      fd.append("restoration[]", el);
    }

    for(let el of artData.support_status){
      fd.append("support_status[]", el);
    }

    for(let el of artData.surface_status){
      fd.append("surface_status[]", el);
    }

    for(let el of artData.packaging){
      fd.append("packaging[]", el);
    }

    Art.Update(props.sessionData.jwt, props.artId, fd, async () => {

      let afd = new FormData();
      afd.append("type", "add");
      let sfd = new FormData();
      sfd.append("type", "side");

      for(let picToKeep of artData.additional_picture){
        afd.append("filter[]", picToKeep.path);
      }
      for(let picToKeep of artData.side_picture){
        sfd.append("filter[]", picToKeep.path);
      }

      await Art.DeletePictures(props.sessionData.jwt, props.artId, afd);
      await Art.DeletePictures(props.sessionData.jwt, props.artId, sfd);

      for(let picToMod of artData.additional_picture){
        let tmpfd = new FormData();
        tmpfd.append("type", "add");
        tmpfd.append("path", picToMod.path);
        tmpfd.append("legend", picToMod.legend);
        await Art.UpdatePicture(props.sessionData.jwt, props.artId, tmpfd);
      }
      for(let picToMod of artData.side_picture){
        let tmpfd = new FormData();
        tmpfd.append("type", "side");
        tmpfd.append("path", picToMod.path);
        tmpfd.append("legend", picToMod.legend);
        await Art.UpdatePicture(props.sessionData.jwt, props.artId, tmpfd);
      }

      for(let picToAdd of addAddedImages ?? []){
        let tmpfd = new FormData();
        tmpfd.append("type", "add");
        tmpfd.append("legend", picToAdd.legend);
        tmpfd.append("picture", await Utilities.GetBlob(picToAdd.path));
        await Art.AddPicture(props.sessionData.jwt, props.artId, tmpfd);
      }
      for(let picToAdd of sideAddedImages ?? []){
        let tmpfd = new FormData();
        tmpfd.append("type", "side");
        tmpfd.append("legend", picToAdd.legend);
        tmpfd.append("picture", await Utilities.GetBlob(picToAdd.path));
        await Art.AddPicture(props.sessionData.jwt, props.artId, tmpfd);
      }
      setOpStatus(false);
      Refresh();
    }, () => {
      setError("Une erreur est survenue. Veuillez réessayer plus tard.");
      setOpStatus(false);
    });
  }

  function deleteHandler(){
    Art.Delete(props.sessionData.jwt, props.artId, () => {
      setOpStatus(true);
      props.setCurrentRoute("home");
      setOpStatus(false);
    }, () => {
      setError("Une erreur est survenue. Veuillez réessayer plus tard.");
      setOpStatus(false);
    })
  }

  function Refresh(){
    setRefresh(!refresh);
  }

  return (
    <div className={loaded ? 'h-adjust p-3' : 'd-flex flex-column h-adjust justify-content-center align-items-center'}>
        {loaded ? 
        <div className="grid-template">
          <div id="print-area">
            <div className="print-section">
              <table>
                <tbody>
                  <tr>
                    <td colSpan={4}><strong>Dernière modification</strong>  {Utilities.DateToLocalFormat(printData.last_modification)}</td>
                    <td colSpan={2}><strong>Création fiche</strong>  {Utilities.DateToLocalFormat(printData.created_at)}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}></td>
                    <td colSpan={2} id="print-inv-num"><strong>Numéro d'inventaire  {printData.inventory_number}</strong></td>
                  </tr>
                  <tr>
                    <td colSpan={4}><strong>Ancien numéro d'inventaire</strong>  {printData.old_inventory_number}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}><strong>Nom de l'auteur</strong> {printData.author_last_name}</td>
                    <td colSpan={2}><strong>Prénom de l'auteur</strong> {printData.author_first_name}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}><strong>Titre</strong> {printData.title}</td>
                    <td colSpan={2}><strong>Atelier</strong> {printData.workshop}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}><strong>Date de création</strong> {printData.art_creation_date}</td>
                    <td colSpan={2}><strong>Année d'étude</strong> {printData.study_year}</td>
                  </tr>
                  <tr>
                    <td><strong>Nombre</strong> {printData.number}</td>
                    <td><strong>H:</strong> {printData.height}</td>
                    <td><strong>L:</strong> {printData.width}</td>
                    <td><strong>P:</strong> {printData.depth}</td>
                    <td><strong>Diamètre</strong> {printData.diameter}</td>
                    <td><strong>Poids:</strong> {printData.weight}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <strong>Encadrement_soclage</strong> {printData.base_framing}<br/>
                      <strong>Description</strong> {printData.descrption}<br/>
                      <strong>Technique</strong> {printData.technique}<br/>
                      <strong>Support</strong> {printData.support}<br/>
                      <strong>Emplacement habituel</strong> {printData.usual_location}<br/>
                      <strong>Date d'acquisition</strong> {Utilities.DateToLocalFormat(printData.acquisition_date)}<br/>
                      <strong>Valeur à l'acquisition</strong> {printData.acquisition_value}<br/>
                      <strong>Mode d'acquisition</strong> {printData.acquisition_method}
                    </td>
                    <td colSpan={2}>
                      <div className="mb-3 image-placehodler">
                        <img src={Utilities.GetDistantImage(printData.main_picture_path)} className="img-contain" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="print-container">
                <p><strong>État du support</strong></p>
                <div className="print-checkboxes">
                  {supportStatusData.map((e, i) => 
                    <Form.Check type="checkbox" label={e} key={i} readOnly checked={printData.support_status.includes(e)} />
                  )}
                </div>
              </div>
              <div className="print-flex">
                <div className="print-container print-flex-left">
                  <p><strong>État de la surface</strong></p>
                  <div className="print-checkboxes-high">
                    {surfaceStatusData.map((e, i) => 
                      <Form.Check type="checkbox" label={e} key={i} readOnly checked={printData.surface_status.includes(e)} />
                    )}
                  </div>
                </div>
                <div className="print-container print-flex-right">
                  <strong>Remarques:</strong> {printData.notes}<br/>
                  <strong>Transportable</strong> {printData.portable ? "Oui" : "Non"}<br/>
                  <strong>État général</strong> {printData.general_status}
                </div>
              </div>
              <div className="print-container">
                <p><strong>Intervention d'urgence</strong></p>
                <div className="print-flex">
                  <div>
                    <p><strong>Proposition de conservation</strong></p>
                    {preservationData.map((e, i) => 
                      <Form.Check type="checkbox" label={e} key={i} readOnly checked={printData.preservation.includes(e)} />
                    )}
                  </div>
                  <div>
                    <p><strong>Proposition de restauration</strong></p>
                    {restorationData.map((e, i) => 
                      <Form.Check type="checkbox" label={e} key={i} readOnly checked={printData.restoration.includes(e)} />
                    )}
                  </div>
                  <div>
                    <p><strong>Remarques</strong></p>
                    <p>{printData.intervention_notes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-panel">
            <button onClick={() => setTab("identification")} className={`tab${tab === "identification" ? " tab-active" : ""}`}>Identification</button>
            <button onClick={() => setTab("emplacement")} className={`tab${tab === "emplacement" ? " tab-active" : ""}`}>Emplacement</button>
            <button onClick={() => setTab("donnees")} className={`tab${tab === "donnees" ? " tab-active" : ""}`}>Données administratives</button>
            <button onClick={() => setTab("constat")} className={`tab${tab === "constat" ? " tab-active" : ""}`}>Constat d'état</button>
            {/*<button onClick={() => setTab("journal")} className={`tab${tab === "journal" ? " tab-active" : ""}`}>Journal</button>*/}
            <div className="tab-content p-3 rounded">
              <Router currentRoute={tab}>
                <ArtConstat 
                  route-id="constat"
                  artData={artData}
                  updateArtData={updateArtData}
                  surfaceStatusData={surfaceStatusData}
                  supportStatusData={supportStatusData}
                  generalStatusData={generalStatusData}
                  packagingData={packagingData}
                  preservationData={preservationData}
                  restorationData={restorationData}
                  addPictureChangeHandler={addPictureChangeHandler}
                  sidePictureChangeHandler={sidePictureChangeHandler}
                  addAddedImages={addAddedImages}
                  sideAddedImages={sideAddedImages}
                  addUpdateLegend={addUpdateLegend}
                  sideUpdateLegend={sideUpdateLegend}
                  onDeletePicture={deletePicture}
                  refresh={refresh}
                  sessionData={props.sessionData}
                />
                <ArtDonnees 
                  route-id="donnees" 
                  acquisitionData={acquisitionData}
                  artData={artData}
                  updateArtData={updateArtData}
                  sessionData={props.sessionData}
                />
                <ArtEmplacement 
                  route-id="emplacement" 
                  artData={artData} 
                  updateArtData={updateArtData}
                  sessionData={props.sessionData}
                />
                <ArtIdentification 
                  route-id="identification"
                  workshopData={workshopData}
                  techniqueData={techniqueData}
                  supportData={supportData}
                  nationalityData={nationalityData}
                  weightData={weightData}
                  studyYearData={studyYearData}
                  image={image}
                  pictureChangeHandler={pictureChangeHandler}
                  artData={artData}
                  updateArtData={updateArtData}
                  sessionData={props.sessionData}
                />
                <ArtJournal route-id="journal" />
              </Router>
            </div>
          </div>
          <div className="side-panel p-3 rounded">
            <Row>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Numéro d'inventaire</Form.Label>
                <Form.Control plaintext readOnly value={printData.inventory_number} type="text" />
              </Form.Group>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Dernière modification</Form.Label>
                <Form.Control plaintext readOnly value={Utilities.DateTimeToLocalFormat(printData.last_modification)} type="text" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Ancien numéro</Form.Label>
                <Form.Control plaintext readOnly value={printData.old_inventory_number} type="text" />
              </Form.Group>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Création de la fiche</Form.Label>
                <Form.Control plaintext readOnly value={Utilities.DateToLocalFormat(printData.created_at)} type="text" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Nom Auteur</Form.Label>
                <Form.Control plaintext readOnly value={printData.author_last_name} type="text" />
              </Form.Group>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Prénom auteur</Form.Label>
                <Form.Control plaintext readOnly value={printData.author_first_name} type="text" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Pseudonyme</Form.Label>
                <Form.Control plaintext readOnly value={printData.author_pseudo} type="text" />
              </Form.Group>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Atelier</Form.Label>
                <Form.Control plaintext readOnly value={printData.workshop} type="text" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Titre</Form.Label>
                <Form.Control plaintext readOnly value={printData.title} type="text" />
              </Form.Group>
              <Form.Group as={Col} className='mb-3'>
                <Form.Label className="fw-bold">Année d'étude</Form.Label>
                <Form.Control plaintext readOnly value={printData.study_year} type="text" />
              </Form.Group>
            </Row>
            <div className="mb-3 image-placehodler">
              <img src={Utilities.GetDistantImage(artData.main_picture_path)} className="img-contain" />
            </div>
            {opStatus ? 
            <img className="form-loading" src={loading}></img> : 
            <div>
              <div className="mb-2 art-ops">
                <Button disabled={props.sessionData.adminRights === 0} onClick={updateHandler} variant="primary">Enregister les modifications</Button>
                <Button onClick={printHandler} variant="primary">Imprimer</Button>
                {/*<Button disabled={props.sessionData.adminRights === 0} variant="primary">Dupliquer</Button>*/}
              </div>
              <Button disabled={props.sessionData.adminRights === 0} onClick={deleteHandler} variant="danger">Supprimer</Button>
            </div>
            }
          </div>
        </div> : 
        error === "" ? <img className="page-loading" src={loading}></img> : <p>{error}</p>}
    </div>
  );
}

export default ModifyArt;