import { Form, Button, Row, Col } from 'react-bootstrap';

function ArtEmplacement(props){
  return (
    <div>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Emplacement habituel</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-usualLocation" onChange={e => props.updateArtData("usual_location", e.target.value)} value={props.artData.usual_location} type="text" placeholder="emplacement" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Localisation</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-location" onChange={e => props.updateArtData("location", e.target.value)} value={props.artData.location} type="text" placeholder="localisation" />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Localications précédentes</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-ancientLocation" onChange={e => props.updateArtData("ancient_location", e.target.value)} value={props.artData.ancient_location} as="textarea" rows={4} placeholder="localications précédentes" />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className='mb-3'>
          <Form.Label>Raison de la localisation</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-locationReason" onChange={e => props.updateArtData("location_reason", e.target.value)} value={props.artData.location_reason} as="textarea" rows={4} placeholder="raison de la localisation" />
        </Form.Group>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Date de départ</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-leaveDay" onChange={e => props.updateArtData("leave_day", e.target.value)} value={props.artData.leave_day} type="date" />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Date de retour</Form.Label>
            <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-returnDay" onChange={e => props.updateArtData("return_day", e.target.value)} value={props.artData.return_day} type="date" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Historique</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-locationHistoric" onChange={e => props.updateArtData("location_historic", e.target.value)} value={props.artData.location_historic} as="textarea" rows={4} placeholder="historique" />
        </Form.Group>
      </Row>
    </div>
  );
}

export default ArtEmplacement;