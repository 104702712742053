import config from "../config";
const apiPath = config.apiPath;

let Utilities = {};

Utilities.DateToYear = (dateString) => {
  if(dateString === "" || dateString === null) return "";
  let date = new Date(dateString);
  return date.getFullYear();
}

Utilities.DateToLocalFormat = (dateString) => {
  if(dateString === "" || dateString === null) return "";
  let date = new Date(dateString);
  return `${date.getDate().toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}/${(date.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}/${date.getFullYear()}`;;
}

Utilities.DateTimeToLocalFormat = (dateString) => {
  if(dateString === "" || dateString === null) return "";
  let date = new Date(dateString);
  return `${date.getDate().toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}/${(date.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}/${date.getFullYear()} ${date.getHours().toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}:${date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits : 2, useGrouping: false})}`;
}

Utilities.GetDistantImage = (distantPath) => {
  return apiPath + distantPath;
}

Utilities.RemoveFromArray = (array, element) => {
  let newArray = [];
  for(let el of array){
    if(element !== el){
      newArray.push(el);
    }
  }
  return newArray;
}

Utilities.Find = (array, arrayKey, el) => {
  let i=0;
  for(let arrEl of array){
    if(arrEl[arrayKey] === el){
      return [i, arrEl];
    }
    i++;
  }
  return [false, false];
}

Utilities.GetBlob = async (imgUrl) => {
  let res = await fetch(imgUrl);
  return res.blob();
}

export default Utilities;