import { Row, Col, Form } from "react-bootstrap";

function ConstatIntervention(props){
  return(
    <div>
      <Row className="mb-3">
        <Col>
          <Form.Label>Proposition de conservation</Form.Label>
          {props.preservationData.map((e, i) => 
            <Form.Check disabled={props.sessionData.adminRights === 0} type="checkbox" label={e} key={i} onChange={() => props.updateMap("preservation", e)} checked={props.artData.preservation.includes(e)} />
          )}
        </Col>
        <Col>
          <Form.Label>Proposition de restauration</Form.Label>
          {props.restorationData.map((e, i) => 
            <Form.Check disabled={props.sessionData.adminRights === 0} type="checkbox" label={e} key={i} onChange={() => props.updateMap("restoration", e)} checked={props.artData.restoration.includes(e)} />
          )}
        </Col>
      </Row>
      <Row>
        <Form.Group className='mb-3'>
          <Form.Label>Remarques</Form.Label>
          <Form.Control readOnly={props.sessionData.adminRights === 0} id="modifyArt-interNotes" onChange={e => props.updateArtData("intervention_notes", e.target.value)} value={props.artData.intervention_notes} as="textarea" rows={4} placeholder="remarques" />
        </Form.Group>
      </Row>
    </div>
  );
}

export default ConstatIntervention;