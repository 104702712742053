import { useState } from "react";
import Router from "./Router";
import SupplierList from "./SupplierList";
import SupplierNew from "./SupplierNew";

function Suppliers(props){
  let [tab, setTab] = useState("list");

  return (
    <div>
      <button onClick={() => setTab("list")} className={`inner-tab${tab === "list" ? " active-inner-tab" : ""}`}>Fournisseurs</button>
      <button onClick={() => setTab("new")} className={`inner-tab${tab === "new" ? " active-inner-tab" : ""}`}>Nouveau</button>
      <div className="inner-tab-content p-3 rounded">
        <Router currentRoute={tab}>
          <SupplierList route-id="list" sessionData={props.sessionData} />
          <SupplierNew route-id="new" onSuccess={setTab} sessionData={props.sessionData} />
        </Router>
      </div>
    </div>
  );
}

export default Suppliers;