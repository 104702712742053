import $ from "jquery";
import { Form, Button } from 'react-bootstrap';
import loading from "../img/loading.svg";
import logo from "../img/lacambre-logo-full.svg";

function Login(props){
  function onConnect(e){
    e.preventDefault();
    let username = $("#login-username").val();
    let password = $("#login-password").val();
    props.onConnect(username, password);
  }
  return (
    <div className='d-flex flex-column h-adjust justify-content-center align-items-center'>
      <div className="login-flex">
        <div className="main-left">
          <div>
            <img className="main-logo" src={logo}></img>
          </div>
          <h3 className="main-title">Collection de La Cambre - Inventaire</h3>
          <Form onSubmit={onConnect} className='main-form bg-light-blue p-4 rounded'>
            <Form.Group className='mb-3'>
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control id="login-username" type="text" placeholder="Michel" />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Mot de Passe</Form.Label>
              <Form.Control id="login-password" type="password" placeholder="Mot de passe top secret !" />
            </Form.Group>
            {props.opStatus ? <img className="form-loading" src={loading}></img> : <Button onClick={onConnect} variant="primary">Envoyer</Button>}
            <Form.Text className="text-muted">
              <br/>{props.loginError}
            </Form.Text>
          </Form>
        </div>
        <div className="main-right">
          <p>
            La collection de La Cambre rassemble plus de 1300 œuvres réalisées par des étudiant•es et des professeur•es de La Cambre, depuis la création de l'école en 1927 à aujourd'hui. Ce projet, en constante évolution, est géré depuis 2009 en collaboration avec l'option de conservation-restauration d'œuvres d'art. Afin de partager cette mémoire collective, La Cambre rend aujourd'hui son inventaire accessible en ligne, pour le communauté de l'école.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;